import { NoteState } from "@/enums/notes";
import type { DealNotesOptions } from "@/models/common";

export const EXTRA_DEAL_VIEW_REQUESTS = [
  "notes",
  "businessNotes",
  "offers"
] as const;

export const DEAL_MULTISELECT_CONTROLS = [
  "underwriter_full_name",
  "advisor_full_name",
  "analyst_full_name",
  "deal_mode"
] as const;

export const DATA_OPTIONS: Record<
  string,
  (typeof DEAL_MULTISELECT_CONTROLS)[number]
> = {
  DEAL_MODE: "deal_mode",
  ADVISOR: "advisor_full_name",
  ANALYST: "analyst_full_name",
  UNDERWRITER: "underwriter_full_name"
};

//role related smartview filter options come with 2 static options: none and current user (these are set on the FE)
export const NUMBER_OF_STATIC_ROLE_OPTIONS = 2 as const;

export const DEAL_SERVICE_TYPES = {
  regular: "regular",
  premier: "premier",
  pdf: "pdf"
} as const;

export const DEFAULT_DEAL_NOTES_OPTIONS: DealNotesOptions = {
  state: NoteState.Closed,
  minimized: false,
  x: 0,
  y: 0,
  width: 400,
  height: 600
};
