<template>
  <div
    v-if="canSeeDealNotesButton"
    class="cursor-pointer relative border ml-2 rounded flex items-center justify-center p-2 transition-all duration-300 h-10 w-10"
    :class="isShowingNotes ? 'bg-primary ' : 'bg-white'"
    data-cy="deal-notes-button"
    @click="handleClick"
  >
    <div
      v-if="unreadMessages?.length"
      class="absolute top-0 right-0 -mt-1.5 -mr-1.5 p-0-5 rounded-full bg-white"
    >
      <div class="bg-red-500 h-2 w-2 rounded-full" />
    </div>
    <icon-base
      :icon="IconDealNotes"
      :icon-color="isShowingNotes ? 'white' : UTIL_COLORS.DEFAULT"
      :opacity="1"
      class="ml-0-5"
    />
  </div>
</template>

<script setup lang="ts">
import { useDeals } from "@/hooks/deals";
import { computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useLocalStorageSetting } from "@/hooks/options";
import useApplicationsStore from "@/stores/applications";
import type { IUser } from "@/models/users";
import { useAuth } from "@/hooks/auth";
import { UTIL_COLORS } from "@/helpers/constants";
import { DEFAULT_DEAL_NOTES_OPTIONS } from "@/helpers/constants/deals";
import { NoteState } from "@/enums/notes";
import IconDealNotes from "@/components/icons/dealNotes/DealNotes.vue";
import { usePromiseWrapper } from "@/hooks/common";

const { getters } = useStore();
const applicationsStore = useApplicationsStore();
const {
  isClient,
  isClientAdmin,
  isClientUnderwriter,
  isLendflowUser,
  isClientUser
} = useAuth();
const {
  activeDeal: deal,
  isActiveDealEquipmentRental,
  isUnderwritingEnabled,
  dealNotes
} = useDeals();
const dealNotesOptions = useLocalStorageSetting(
  "dealNotesOptions",
  DEFAULT_DEAL_NOTES_OPTIONS
);

const user = computed<IUser>(() => getters["auth/user"]);
const isShowingNotes = computed(
  () => dealNotesOptions.value.state !== NoteState.Closed
);

const unreadMessages = computed(
  () =>
    dealNotes.value?.filter(
      (note) =>
        !note.read_notes?.find((record) => record.user.id === user.value.id)
    ) ?? []
);

const isFundingApp = computed(
  () => !deal.value.is_business_credit && !deal.value.is_equipment_rental
);

const businessCreditPermission = computed(() => isClient || isLendflowUser);

const equipmentRentalPermission = computed(
  () =>
    isActiveDealEquipmentRental.value &&
    (((isClientAdmin || isClientUnderwriter) && isUnderwritingEnabled.value) ||
      isLendflowUser)
);

const fundingPermission = computed(
  () => isFundingApp.value && (isLendflowUser || isClient)
);

const canSeeDealNotesButton = computed(
  () =>
    businessCreditPermission.value ||
    equipmentRentalPermission.value ||
    fundingPermission.value ||
    isClientUser
);

// We have to fetch notes here so that we can show how many unread notes there are
const { fetchWrapper: getNotes } = usePromiseWrapper(async () => {
  await applicationsStore.getApplicationNotes({
    applicationId: deal.value.id,
    types: ["application"]
  });
});

const handleClick = () => {
  if (isShowingNotes.value) {
    dealNotesOptions.value = {
      ...dealNotesOptions.value,
      state: NoteState.Closed
    };
    return;
  }
  dealNotesOptions.value = {
    ...dealNotesOptions.value,
    state: NoteState.Static
  };
};

onMounted(() => {
  applicationsStore.canSeeNotes = canSeeDealNotesButton.value;
  getNotes();
});
onUnmounted(() => {
  applicationsStore.canSeeNotes = false;
});
</script>
